import React, { useRef } from 'react'
import './contact.css'

const Contact = () => {
  return (
    <div className={`contact-container`}>
      <div className={`contact-header`}>
        <div className={`contact-title`}>
          <h1> Get in touch.</h1>
        </div>
        <div className={`contact-empty-space`}> </div>

        <div className={`contact-desc`}>
          <p> team@elkanamunganga.com</p>
        </div>
      </div>

      <div className={`contact-form`}>
        <form onSubmit={``} >
          <label htmlFor="id-name">Name *</label>
          <input
            id='id-name'
            name='name'
            placeholder='Name'
            type='text'
            required
            className={`form-input`}
            autoComplete='on'
          />
          <label htmlFor="id-email">Email *</label>
          <input
            id='id-email'
            name='email'
            placeholder='Email'
            type='email'
            required
            className={`form-input`}
            autoComplete='on'
          />
          <label htmlFor="id-select">Purpose of email</label>
          <select className={`form-select`} name='service' id='id-select'>
            <option value="Business">Business Inquiries</option>
            <option value="Referrals">Referrals</option>
            <option value="CustomerService">Customer Service</option>
            <option value="Other">Other...</option>
          </select>
          <label  htmlFor="id-message">Message *</label>
          <textarea
            name='message'
            id='id-message'
            placeholder='Message...'
            maxLength="500"
            className='form-textarea'
            autoComplete='off'
          />
          <div className={`contact-empty-space`}></div>
          <input
            name='submit'
            type='submit'
            className={`form-submit`}
          />
        </form>
      </div>
    </div>
  )
}

export default Contact