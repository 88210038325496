import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Hero, Projects, Footer } from './components';
import { Atlantis, Software, Explore, Photography, Portfolio, Shop, Contact } from './pages';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        {/* <div> */}
        <Navbar />
        <Routes>
          <Route path="/" element={<>
            <Hero />
            <Projects />
          </>}
          />
          <Route path={`/portfolio`} element={<Portfolio />}
          />
          <Route path={`/portfolio/atlantis`} element={<Atlantis />} />
          <Route path={`/portfolio/software`} element={<Software />} />
          <Route path={`/portfolio/explore`} element={<Explore />} />
          <Route path={`/portfolio/photography`} element={<Photography />} />

          <Route path={`/shop`} element={<Shop />} />
          <Route path={`/contact`} element={<Contact />} />
        </Routes>
        <Footer />
        {/* </div> */}
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
