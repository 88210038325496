import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { projectData } from '../../data/projectData';
import { rightarrow, leftarrow } from '../../imports';
import './portfolio.css';

const Portfolio = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);

  const navigateTo = (page) => {
    navigate(`${page}`);
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % projectData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + projectData.length) % projectData.length);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    let autoSlide;
    if (isLargeScreen) {
      autoSlide = setInterval(nextSlide, 5000); // Auto slide every 5 seconds
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (autoSlide) {
        clearInterval(autoSlide);
      }
    };
  }, [isLargeScreen]);

  return (
    <div className="portfolio-data-container">
      {projectData.map((data, id) => (
        <div
          className={`portfolio-data ${id === currentSlide ? 'active' : ''}`}
          key={id}
          style={{ transform: `translateX(-${currentSlide * (isLargeScreen ? 60 : 0)}%)` }}
        >
          <div className="portfolio-portfoliobg">
            <img src={data.projectBg} alt={data.type} onClick={() => navigateTo(data.link)} />
          </div>
          <div className="portfolio-text">
            <div className="portfolio-logo">
              <img src={data.logo} alt={`${data.name} logo`} />
            </div>
            <div className="portfolio-empty-space"></div>
            <div className="portfolio-type">{data.type}</div>
            <div className="portfolio-name">{data.name}</div>
          </div>
        </div>
      ))}

{isLargeScreen && (
        <>
          <button className="prev" onClick={prevSlide}>
            <img src={leftarrow} alt="Previous Slide" />
          </button>
          <button className="next" onClick={nextSlide}>
            <img src={rightarrow} alt="Next Slide" />
          </button>
        </>
      )}
      <Outlet />
    </div>
  );
};

export default Portfolio;
