import React from 'react'
import { arrow } from '../../imports'
import './hero.css'

const Hero = () => {
  return (
    <section className={`hero-container`}>
      <div className={`hero-video`}>
        <video className={`background-video`} autoPlay loop playsInline muted src={`https://res.cloudinary.com/ddtbiyfpj/video/upload/v1715781824/Elkana%20Branding/IN-HOUSE/PROMO/Web_Video_low_ioo3su.mp4`} preload='metadata' width={`640`} height={`360`} />
        <div className={`video-overlay`}></div>
      </div>
      <div className={`hero-title`}>
        <div className={`hero-text`}>
          <h1>Elkana Munganga</h1>
        </div>
        <div className={`hero-empty-space`}></div>
        <div  className={`hero-icon`}>
          <a href="#projects">
            <img src={arrow} alt={`scroll down`}/>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Hero