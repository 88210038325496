import React from 'react'
import { useNavigate } from 'react-router-dom'
import { projectData } from '../../data/projectData'
import './projects.css'


const Projects = () => {
  const firstThreeProjects = projectData.slice(0,3);
  const Navigate = useNavigate();
  const navigateTo = (page) => {
    Navigate(page);
  };

  return (
    <section className={`project-container`} id='projects'>
      <div className={`project-title`}>
        <h1>Projects</h1>
      </div>
      <div className={`project-data-container`}>
      {
        firstThreeProjects.map((data, id) => (
          <div className={`project-data`} key={id}>
            <div className={`project-projectbg`}>
              <img src={data.projectBg} alt={data.type} />
            </div>
            <div className={`project-text`}>
              <div className={`project-logo`}>
                <img src={data.logo} alt={`${data.name} logo`} />
              </div>
              <div className='projects-empty-space'></div>
              <div className={`project-type`}> {data.type}</div>
              <div className={`project-name`}> {data.name}</div>
            </div>
          </div>
        ))
      }
      </div>
      <div className='projects-empty-space-2'></div>

      <div className='project-button'>
        <button  onClick={() => navigateTo('/portfolio')} >VIEW ALL</button>
      </div>
    </section>
  )
}

export default Projects