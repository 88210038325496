import React, { useState, useEffect, useCallback } from 'react';
import { rightarrow, leftarrow, closewhite } from '../../imports';
import './imageViewer.css';

const ImageViewer = ({ images, initialIndex, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const nextImage = useCallback(() => {
    setCurrentIndex((currentIndex + 1) % images.length);
  }, [currentIndex, images.length]);

  const prevImage = useCallback(() => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  }, [currentIndex, images.length]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        nextImage();
      } else if (event.key === 'ArrowLeft') {
        prevImage();
      } else if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [nextImage, prevImage, onClose]);


  return (
    <div className="image-viewer">
      <div className="image-viewer-overlay" onClick={onClose}></div>
      <div className="image-viewer-content">
        <img className="image-in-view" src={images[currentIndex]} alt={`Slide ${currentIndex}`} />
        
        <img src={leftarrow} alt='leftarrow' className="prev-button" onClick={prevImage} />
        <img src={rightarrow} alt='rightarrow' className="next-button" onClick={nextImage} />
        <img src={closewhite} alt='close' className="close-button" onClick={onClose} />
      </div>
    </div>
  );
};

export default ImageViewer;