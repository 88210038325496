import { laJolla, atlantis, logo_w } from '../imports'

export const projectData = [
    {
      id: '1',
      projectName: 'ATLANTIS',
      name: 'ATLANTIS - GAIA',
      type: 'Video Production',
      logo:`${atlantis}`,
      about: 'GAIA is the second line of Atlantis. Atlantis sent me on a mission to create challenge-based short-form content showcasing the product’s Key Selling Points. My created content has been published on my social media accounts and Atlantis’ official global social media channel. My work helped showcase and convince other existing and new Atlantis clients to buy this perfume and enjoy their very own taste.',
      projectBg: 'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1715059844/Elkana%20Branding/Clients/Atlantis%20by%20Paez/Atlantis_afiy3u.png',
      photos: [],
      videos: [
        'https://res.cloudinary.com/ddtbiyfpj/video/upload/v1715059846/Elkana%20Branding/Clients/Atlantis%20by%20Paez/GAIA_by_Atlantis_bfdhas.mp4'
      ],
      apps:[
        {
          name:'',
          snippet:'',
          link:''
        }, 
      ],
      link: 'atlantis'
    },
    {
      id: '2',
      projectName: 'LaJolla',
      name: 'La Jolla Shores Hotel',
      type: 'Drone',
      logo:`${laJolla}`,
      about: 'This project was about ...',
      projectBg: 'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1715799205/Elkana%20Branding/IN-HOUSE/kan-fpv/img/Day_20_uz9dqs.jpg',
      photos: [
        'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1715799205/Elkana%20Branding/IN-HOUSE/kan-fpv/img/Day_20_uz9dqs.jpg',
        'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1715799205/Elkana%20Branding/IN-HOUSE/kan-fpv/img/Day_20_uz9dqs.jpg'
      ],
      videos: [
        '',
        ''
      ],
      apps:[
        {
          name:'',
          snippet:'',
          link:''
        }, 
      ],
      link: '/portfolio'
    },
    {
      id: '3',
      projectName: 'SJBarbershop',
      name: 'S&J Barbershop',
      type: 'Social Media Management',
      logo:'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1715801381/Elkana%20Branding/Clients/SJ%20Barbershop/Design%20Kits/Version_3_white_csqaxc.png',
      about: 'This project was about ...',
      projectBg: 'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1715061479/Elkana%20Branding/Clients/SJ%20Barbershop/Stills/DSC00392_copy_zsij3r.jpg',
      photos: [
        'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1715061479/Elkana%20Branding/Clients/SJ%20Barbershop/Stills/DSC00392_copy_zsij3r.jpg',
        'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1715061479/Elkana%20Branding/Clients/SJ%20Barbershop/Stills/DSC00392_copy_zsij3r.jpg'
      ],
      videos: [
        '',
        ''
      ],
      apps:[
        {
          name:'',
          snippet:'',
          link:''
        }, 
      ],
      link: '/portfolio'
    },
    {
      id: '4',
      projectName: 'WEBAPP',
      name: 'Web & App Development',
      type: 'Software Development',
      logo:logo_w,
      about: 'We design and craft your mobile and web application',
      projectBg: 'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1717387903/Elkana%20Branding/IN-HOUSE/kan-fpv/img/DSC08573_dbyqrp.jpg',
      photos: [],
      videos: [],
      apps:[
        {
          name:'AM Consulting',
          snippet:'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1717216610/Elkana%20Branding/Clients/AM%20Consulting/AM_Consulting_gxwhss.png',
          link:'https://amconsulting.company/'
        },
        {
          name:'Teeth Bank - Mobile App',
          snippet:'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1717388588/Elkana%20Branding/Clients/TeethBank/IMG_2442_smqjrf.jpg',
          link:'https://apps.apple.com/us/app/teethbank/id6451276060'
        },
        {
          name:'Luxe Valet',
          snippet:'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1717216565/Elkana%20Branding/Clients/Luxe%20Valet/Snippet/Luxe_Valet_pa8tt1.png',
          link:'https://www.luxevalet.ca/'
        },
        {
          name:'FastLane Valets',
          snippet:'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1717216205/Fastlane%20Valet/Snippet/FastLane_Valets_jpfs5s.png',
          link:'https://www.fastlanevalets.com/'
        },
        {
          name:'SJ Barbershop',
          snippet:'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1717215826/Elkana%20Branding/Clients/SJ%20Barbershop/Web/SJ_Barbershop_ztr2te.png',
          link:'https://sjbarber.com/'
        },

      ],
      link: 'software'
    },
    {
      id: '5',
      projectName: 'EXPLORE',
      name: 'Explore Earth',
      type: 'Photography, Drone Videos',
      logo:`${logo_w}`,
      about: 'Enjoy some of my latest videos and photos cpatured with the latest DJI Mini 4 pro. Most of these videos were shots in 4k',
      projectBg: 'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1717389464/Elkana%20Branding/IN-HOUSE/kan-fpv/img/Day_30_wtokw3.jpg',
      photos: [
        'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1717392021/Elkana%20Branding/IN-HOUSE/kan-fpv/img/Day_33_ggx9mb.jpg',
        'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1717392049/Elkana%20Branding/IN-HOUSE/kan-fpv/img/Day_22_zyi1v6.jpg',
        'https://res.cloudinary.com/ddtbiyfpj/image/upload/v1717392049/Elkana%20Branding/IN-HOUSE/kan-fpv/img/Day_21_q2pu0h.jpg'
      ],
      videos: [
        'https://res.cloudinary.com/ddtbiyfpj/video/upload/v1717363456/Elkana%20Branding/IN-HOUSE/kan-fpv/Videos/Day_50_-_Project_30_mb31qc.mp4',
        'https://res.cloudinary.com/ddtbiyfpj/video/upload/v1717363410/Elkana%20Branding/IN-HOUSE/kan-fpv/Videos/Day_40c_-_Project_30_vlzvsf.mp4',
        'https://res.cloudinary.com/ddtbiyfpj/video/upload/v1717363538/Elkana%20Branding/IN-HOUSE/kan-fpv/Videos/day1_tcuwf5.mp4',
        'https://res.cloudinary.com/ddtbiyfpj/video/upload/v1717363280/Elkana%20Branding/IN-HOUSE/kan-fpv/Videos/Day_37c_-_Project_30_pkmzdd.mp4',
        'https://res.cloudinary.com/ddtbiyfpj/video/upload/v1717363122/Elkana%20Branding/IN-HOUSE/kan-fpv/Videos/Day_23_-_Project_30_myj08r.mp4',
        'https://res.cloudinary.com/ddtbiyfpj/video/upload/v1717363061/Elkana%20Branding/IN-HOUSE/kan-fpv/Videos/Day_19b_-_Project_30_umdklm.mp4',
      ],
      apps:[
        {
          name:'',
          snippet:'',
          link:''
        }, 
      ],
      link: 'explore'
    },
    {
      id: '6',
      projectName: 'PHOTOGRAPHY',
      name: 'Photography',
      type: 'Photography',
      logo:`${logo_w}`,
      about: 'Photography work',
      projectBg: 'https://images.pexels.com/photos/11423668/pexels-photo-11423668.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      photos: [
        'https://images.pexels.com/photos/2702823/pexels-photo-2702823.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        'https://images.pexels.com/photos/1006195/pexels-photo-1006195.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        'https://images.pexels.com/photos/11423668/pexels-photo-11423668.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        'https://images.pexels.com/photos/4010489/pexels-photo-4010489.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', 'https://images.pexels.com/photos/11191614/pexels-photo-11191614.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
      ],
      videos: [],
      apps:[
        {
          name:'',
          snippet:'',
          link:''
        }, 
      ],
      link: 'photography'
    },

  ];
