import React, { useState } from 'react';
import { projectData } from '../../../../data/projectData';
import ImageViewer from '../../../../components/imageViewer/ImageViewer';
import './software.css';


const Software = () => {
    const softwareData = projectData.find(project => project.projectName === 'WEBAPP');
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    if (!softwareData) {
        return <div>Project data not found!</div>
    }

    const openViewer = (index) => {
        setCurrentImageIndex(index);
        setIsViewerOpen(true);
    };

    const closeViewer = () => {
        setIsViewerOpen(false);
    };

    const hasPhotos = softwareData.photos && softwareData.photos.length > 0;
    const hasVideos = softwareData.videos && softwareData.videos.length > 0;
    const validApps = softwareData.apps.filter(app => app.snippet && app.snippet !== '');
    const hasApps = validApps && validApps.length > 0;

    return (
        <div className={`proj-software-container`}>
            <div className={`proj-software-title`}>
                <div className={`proj-software-header`}>
                    <h1>{softwareData.name}</h1>
                </div>
                <div className={`proj-software-empty-space`}></div>
                <div className={`proj-software-desc`}>
                    <p>{softwareData.about}</p>
                </div>
            </div>

            <div className={`proj-software-empty-space`}></div>

            <div className={`proj-software-contents`}>
                {
                    hasApps && softwareData.apps.map((app,id)=>(
                        <div className="proj-software-apps" key={id}>
                            <a href={app.link} target="_blank" rel="noopener noreferrer">
                                <div className="iframe-wrapper">
                                    <img src={app.snippet} alt={`App ${id}`} className="app-iframe"/>
                                    <div className="iframe-overlay">{app.name}</div>
                                </div>
                            </a>
                        </div>
                    ))
                }
                {
                    hasVideos && softwareData.videos.map((video, id) => (
                        <div className={`proj-software-video`} key={id}>
                            <video controls>
                                <source src={video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ))
                }
                {
                    hasPhotos && softwareData.photos.map((data, id) => (
                        <div className={`proj-software-data`} key={id} onClick={() => openViewer(id)} >
                            <img src={data} alt={`Pic-${id}`} />
                        </div>
                    ))
                }
            </div>
            {isViewerOpen && (
                <ImageViewer
                    images={softwareData.photos}
                    initialIndex={currentImageIndex}
                    onClose={closeViewer}
                />
            )}
        </div>
    )
}

export default Software