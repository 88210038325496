import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { openBtn, openBtn_w, closeBtn, logo_b, logo_w } from '../../imports'
import 'font-awesome/css/font-awesome.min.css';
import './navbar.css'

const Navbar = () => {
  const [selectedMenu, setSelectedMenu] = useState('Home');
  const [openMenu, setOpenMenu] = useState(false);
  const [isWhiteBackground, setIsWhiteBackground] = useState(false);
  const location = useLocation();

  const menuPages = [
    { name: 'Portfolio', url: '/portfolio' },
    { name: 'Shop', url: '/shop' },
    { name: 'Contact', url: '/contact' }
  ]
  const handleMenuClick = (menuName) => {
    setSelectedMenu(menuName);
  }

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  }

  useEffect(() => {
    if (location.pathname.startsWith('/portfolio/') || location.pathname.startsWith('/shop')|| location.pathname.startsWith('/contact')) {
      setIsWhiteBackground(true);
    } else {
      setIsWhiteBackground(false);
    }
  }, [location]);

  return (
    <div className={`navbar-container  ${openMenu ? 'open' : ''}`}>
      <div className={`navbar-social  ${isWhiteBackground ? 'black' : ''}`}>
        <a href="https://www.instagram.com/kan.fpv/" target="_blank" rel="noopener noreferrer" ><i class="fa fa-instagram"></i></a>
        <a href="https://www.youtube.com/channel/UCxaJiqhCgKp6huoT3_QtTTw" target="_blank" rel="noopener noreferrer" ><i class="fa fa-youtube"></i></a>
      </div>

      <div className={`navbar-logo`}>
        <Link to="/"><img src={isWhiteBackground ? logo_b : logo_w} alt='logo' /></Link>
      </div>
      <div className={`navbar-menus ${isWhiteBackground ? 'black' : ''}`}>
        {
          menuPages.map((menuItem) => (
            <p
              key={menuItem.name}
              onClick={() => handleMenuClick(menuItem.name)}
              className={`${selectedMenu === menuItem.name ? 'selected' : ''}`}
            >
              <Link to={menuItem.url}>
                {menuItem.name}
              </Link>
            </p>

          ))
        }
      </div>

      {/* Mobile Menu */}
      <div className={`mobile-menu-container`}>
        <div className={`mobile-menu-logo`}>
          <Link to="/"><img src={isWhiteBackground ? logo_b : logo_w} alt='logo' /></Link>
        </div>

        <div>
          <img src={isWhiteBackground ? openBtn_w:openBtn} alt='open button' onClick={toggleMenu} />
        </div>
      </div>
      {
        openMenu &&
        <div className={`mobile-menu`}>
          <div>

            <img src={closeBtn} alt='close button' onClick={toggleMenu} />
          </div>
          {
            menuPages.map((menuItem) => (
              <p
                key={menuItem.name}
                onClick={() => handleMenuClick(menuItem.name)}
                className={`${selectedMenu === menuItem.name ? 'selected' : ''}`}
              >
                <Link to={menuItem.url}>
                  {menuItem.name}
                </Link>
              </p>

            ))
          }
        </div>
      }
    </div>
  )
}

export default Navbar