import React from 'react'
import './shop.css'

const Shop = () => {
  return (
    <div className={`shop-container`}>
      <div className={`shop-header`}>
        <div className={`shop-title`}>
          <h1>All things creative</h1>
        </div>
        <div className={`shop-empty-space`}></div>
        <div className={`shop-desc`}>
          <p>Join the waitlist to get notified when to receive your free items</p>
        </div>
      </div>

      <div className={`shop-textbox`}>
        <input
          placeholder='Email'
          name='Email'
          type='email'
        />
        <button 
          name='Join'
          id='join'
          type='submit'
        >Join</button>
      </div>
    </div>
  )
}

export default Shop